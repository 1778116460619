import React, { SVGProps } from "react";

export const HistorySVG: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      id="validationIcon"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        d="M21 4.5C21 3.11929 16.9706 2 12 2C7.02944 2 3 3.11929 3 4.5C3 5.88071 7.02944 7 12 7C14.0791 7 15.9936 6.80417 17.5173 6.47528"
        stroke="current"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 4.58939V19.5C3 20.8807 7.02944 22 12 22C16.9706 22 21 20.8807 21 19.5V4.58939"
        stroke="current"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.7137 11L9.21371 17.4315L6.71371 11"
        stroke="current"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.2967 11.7258L16.4198 10.5245V17.4315"
        stroke="current"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
