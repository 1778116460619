import React, { Fragment, useEffect, useState } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { Dialog, Transition } from "@headlessui/react";
import {
  BellIcon,
  CogIcon,
  CreditCardIcon,
  UserCircleIcon,
  XIcon,
  ExclamationIcon,
  CheckCircleIcon,
} from "@heroicons/react/outline";
import { DocumentData } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../../../../services/firebase";

const subNavigation = [
  { name: "General", href: "#", icon: CogIcon, current: true },
  { name: "Team", href: "#", icon: UserCircleIcon, current: false },
  // { name: "Notifications", href: "#", icon: BellIcon, current: false },
  { name: "Billing", href: "#", icon: CreditCardIcon, current: false },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  project?: DocumentData | undefined;
}

export const EditProject: React.FC<Props> = ({ open, setOpen, project }) => {
  const [navigation, setNavigation] = useState(0);
  const [projectName, setProjectName] = useState(project?.data().name || "");
  const [user] = useAuthState(auth);
  // const [projectOwner, setProjectOwner] = useState<DocumentData>();

  useEffect(() => {
    const fetchOwner = async () => {
      if (project && project.data().owner) {
        const q = query(
          collection(db, "user"),
          where("uid", "==", project?.data().owner)
        );
        const docs = await getDocs(q);
        //  setProjectOwner({
        //    name: doc.data().displayName || "Owner's Name",
        //    email: doc.data().email || "Owner's email",
        //    isVerified: doc.data().emailVerified || false,
        //  });
      }
    };
    // fetchOwner();
  }, [project, user?.uid]);

  useEffect(() => {
    if (project) setProjectName(project.data().name);
  }, [project]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-1000" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed z-40 inset-0 bg-gray-500 dark:bg-gray-800 bg-opacity-75 dark:bg-opacity-75 transition-opacity w-screen" />
        </Transition.Child>

        <div className="fixed z-40 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full text-center sm:p-0">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white dark:bg-dark-800 rounded-lg text-left overflow-hidden shadow-xl transform transition-all ">
                <div>
                  <main className="relative">
                    <div className="mx-auto">
                      <div className="bg-white dark:bg-dark-800 rounded-lg shadow overflow-hidden">
                        <div className="block absolute top-0 right-0 pt-4 pr-4">
                          <button
                            type="button"
                            className="bg-white rounded-md text-gray-400 dark:bg-transparent dark:text-gray-200 dark:hover:text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:focus:ring-transparent"
                            onClick={() => setOpen(false)}
                          >
                            <span className="sr-only">Close</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                        <div className="divide-y divide-gray-200 dark:divide-dark-500 mt-6 lg:mt-0 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
                          <aside className="py-6 lg:col-span-3">
                            <nav className="space-y-1">
                              {subNavigation.map((item, idx) => (
                                <a
                                  key={item.name}
                                  href={item.href}
                                  className={classNames(
                                    idx === navigation
                                      ? "bg-indigo-200 border-indigo-500 text-indigo-700 hover:bg-teal-50 hover:text-indigo-500 dark:bg-dark-button dark:text-gray-200 dark:hover:text-gray-100 dark:border-highlight-dark dark:hover:bg-highlight-dark transition"
                                      : "border-transparent text-gray-900 hover:bg-gray-50 dark:hover:bg-[#01586e] hover:text-gray-900 dark:text-gray-500 dark:hover:text-gray-400 transition",
                                    "group border-l-4 px-3 py-2 flex items-center text-sm font-medium"
                                  )}
                                  aria-current={
                                    item.current ? "page" : undefined
                                  }
                                  onClick={() => setNavigation(idx)}
                                >
                                  <item.icon
                                    className={classNames(
                                      idx === navigation
                                        ? "text-indigo-500 group-hover:text-indigo-500 dark:text-gray-200 dark:group-hover:text-gray-100"
                                        : "text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-400",
                                      "flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                                    )}
                                    aria-hidden="true"
                                  />
                                  <span className="truncate">{item.name}</span>
                                </a>
                              ))}
                            </nav>
                          </aside>

                          <form
                            className="lg:col-span-9 h-[520px]  flex flex-col w-screen lg:w-[575px] max-w-[575px] overflow-y-auto"
                            action="#"
                            method="POST"
                          >
                            <div className="flex-1">
                              {/* Project Config */}
                              {navigation === 0 && (
                                <div className="px-2 sm:px-3 py-3">
                                  <div className="border-b-2 border-gray-200 dark:border-gray-700 py-3">
                                    <h2 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">
                                      Project Information
                                    </h2>
                                    <p className="mt-1 text-sm text-gray-500 dark:text-gray-300">
                                      Adjust general the settings of your
                                      project
                                    </p>
                                  </div>
                                  <div className="px-2 pt-3 pb-6 sm:px-3 ">
                                    <div className="grid grid-cols-12 gap-6">
                                      <div className="col-span-12">
                                        <label
                                          htmlFor="project-name"
                                          className="block text-sm font-medium text-gray-700 dark:text-gray-300"
                                        >
                                          Project name
                                        </label>
                                        <input
                                          type="text"
                                          name="name"
                                          id="project-name"
                                          value={projectName}
                                          placeholder={
                                            project?.data().name || ""
                                          }
                                          onChange={({ target: { value } }) =>
                                            setProjectName(value)
                                          }
                                          className="mt-1 block w-full border border-gray-300 dark:bg-dark-500 dark:border-gray-700 dark:text-gray-200  rounded-md shadow-sm py-2 px-3 focus:outline-none  focus:ring-indigo-500 focus:border-indigo-500 dark:focus:ring-sky-500 dark:focus:border-sky-500 sm:text-sm"
                                        />
                                      </div>
                                    </div>
                                    <div className="mt-4 justify-between items-center ">
                                      <label
                                        htmlFor="blockchain"
                                        className="block text-sm font-medium text-gray-700 dark:text-gray-300 mr-4"
                                      >
                                        Blockchain
                                      </label>
                                      <select
                                        id="blockchain"
                                        name="blockchain"
                                        className="mt-1 block w-full flex-1 pl-3 pr-10 py-2 text-base border-gray-300 dark:bg-dark-500 dark:text-gray-200 dark:border-gray-700 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 dark:focus:ring-sky-500 dark:focus:border-sky-500 sm:text-sm rounded-md"
                                        defaultValue="WAX mainnet"
                                      >
                                        <option value="wax mainnet">
                                          WAX Mainnet
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                  <div>
                                    <h2 className="text-lg leading-6 font-bold pb-3 dark:text-gray-300">
                                      Danger Zone
                                    </h2>

                                    <div className="px-2 sm:px-3 ring-[1px] ring-[#ff8080] dark:ring-red-700 ring-opacity-75 rounded-md divide-y divide-gray-200 dark:divide-gray-700 text-sm ">
                                      <div className="pt-3 pb-4">
                                        <p className="block font-bold dark:text-gray-300">
                                          Delete Project
                                        </p>
                                        <div className="flex justify-between items-center">
                                          <div className="flex flex-grow mt-1 md:mt-0 mr-4">
                                            <ExclamationIcon
                                              className="h-5 w-5 text-red-500 dark:text-red-600  mr-2 flex-shrink-0"
                                              aria-hidden="true"
                                            />
                                            <p className="text-sm dark:text-gray-200">
                                              Once you delete a project, there's
                                              no going back.
                                            </p>
                                          </div>
                                          <div className="flex-shrink-0">
                                            <button className="mt-1 md:mt-0 w-full border whitespace-nowrap border-red-300 dark:bg-red-700 dark:text-gray-100 dark:border-red-900 rounded-md shadow-sm py-2 px-4 focus:outline-none focus:ring-red-500 focus:border-red-500 text-sm hover:bg-red-700 transition duration-200 hover:text-white">
                                              Delete this project
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {/* Team Config  */}
                              {navigation === 1 && (
                                <div className="px-2 sm:px-3 py-3">
                                  <div className="border-b-2 border-gray-200 dark:border-gray-700 py-3">
                                    <h2 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">
                                      Team Members
                                    </h2>
                                    <p className="mt-1 text-sm text-gray-500 dark:text-gray-300">
                                      Invite team members and assign roles
                                    </p>
                                  </div>
                                  <div className="px-2 pt-3 pb-6 sm:px-3 ">
                                    <div className="justify-between items-center ">
                                      <label
                                        htmlFor="blockchain"
                                        className="block text-sm font-medium text-gray-700 dark:text-gray-300 mr-4"
                                      >
                                        Blockchain
                                      </label>
                                      <select
                                        id="blockchain"
                                        name="blockchain"
                                        className="mt-1 block w-full flex-1 pl-3 pr-10 py-2 text-base border-gray-300 dark:bg-dark-500 dark:text-gray-200 dark:border-gray-700 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                        defaultValue="WAX mainnet"
                                      >
                                        <option value="wax mainnet">
                                          WAX Mainnet
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {/* Billing Config  */}
                              {navigation === 2 && (
                                <div className="px-2 sm:px-3 py-3">
                                  <div className="border-b-2 border-gray-200 dark:border-gray-700 py-3">
                                    <h2 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">
                                      Billing Information
                                    </h2>
                                    <p className="mt-1 text-sm text-gray-500 dark:text-gray-300">
                                      The owner of the project is billed. To
                                      adjust the billing information, the owner
                                      can head over to the billing page
                                    </p>
                                  </div>
                                  <div className="px-3">
                                    <div className="bg-gray-100 dark:bg-dark-600 mt-6 bg-opacity-20 p-4 shadow-lg shadow-slate-300 dark:shadow-dark-700 rounded-lg flex items-center">
                                      <div className="mb-4 flex-shrink-0 sm:mb-0 sm:mr-4">
                                        {user && (
                                          <img
                                            src={user.photoURL ?? ""}
                                            alt={user.displayName + " avatar"}
                                            className="rounded-full mr-2 h-[4.25rem] w-[4.25rem]"
                                          />
                                        )}
                                      </div>
                                      <div>
                                        <h4 className="text-lg font-bold dark:text-gray-100">
                                          {user?.displayName || "Owner"}
                                        </h4>
                                        <p className="text-gray-500 text-sm flex items-center dark:text-gray-300">
                                          {user?.email || "Owner's email"}
                                        </p>
                                        <p className="text-gray-700 text-xs mt-4 uppercase tracking-wide font-bold dark:text-gray-400">
                                          Project Owner
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>

                            <div className="mt-4 py-4 px-4 flex justify-end sm:px-6">
                              <button
                                type="button"
                                className="bg-white border border-gray-300 dark:bg-dark-500 dark:border-gray-700 dark:text-gray-100 rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                onClick={() => {
                                  setOpen(false);
                                }}
                              >
                                Cancel
                              </button>
                              <button
                                type="submit"
                                className="ml-5 bg-indigo-700 dark:bg-dark-button dark:hover:bg-highlight-dark border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-gray-400 dark:disabled:bg-gray-400 transition focus:ring-transparent"
                              >
                                Save
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </main>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
