import React from "react";

import { PlusIcon } from "@heroicons/react/outline";

export function NoSubscriptions() {
  return (
    <div className="text-center mt-4 p-4 pb-6 bg-white dark:bg-dark-600 dark:bg-opacity-60 shadow overflow-hidden sm:rounded-md">
      <svg
        className="mx-auto h-12 w-12 text-gray-400 dark:text-gray-300"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
        />
      </svg>
      <h3 className="mt-2 text-sm font-medium text-gray-900 dark:text-gray-100">
        No Subscriptions
      </h3>
      <p className="mt-1 text-sm text-gray-500 dark:text-gray-300">
        You don't have any subscriptions yet. Start by creating your first
        project
      </p>
      <div className="mt-6">
        <a href="/plans/select">
          {/* TODO - Add hover and focus color */}
          <button
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-black dark:bg-gray-700 dark:bg-opacity-60 dark:border dark:text-gray-200 dark:border-gray-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:border-black hover:text-black hover:bg-white sm:w-auto dark:hover:bg-dark-400 dark:hover:text-gray-100 dark:hover:border-gray-700 transition duration-100"
          >
            <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            Create Project
          </button>
        </a>
      </div>
    </div>
  );
}
