import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

export const firebaseConfig = {
  apiKey: "AIzaSyB6WFokQEXvNFMdwKXG2VvnGcMZbuEek1w",
  authDomain: "blkapi-c08e7.firebaseapp.com",
  projectId: "blkapi-c08e7",
  storageBucket: "blkapi-c08e7.appspot.com",
  messagingSenderId: "1040133231633",
  appId: "1:1040133231633:web:83c180d9493d90f9ad0db0",
  measurementId: "G-QCP1MLS57W",
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
