import React, { Fragment, useState } from "react";
import { CheckCircleIcon } from "@heroicons/react/solid";
import { RadioGroup } from "@headlessui/react";
import { useStripe } from "@stripe/react-stripe-js";
import { fetchFromAPI } from "../../../helpers/fetchApi";

const pricingTiers = [
  {
    id: 1,
    title: "Develop",
    description:
      "Ideal for small projects that just want to get started on EOSIO blockchains",
    basePrice: "19€ / mo",
    basePriceDescription: "1m included requests per month",
    usagePrice: "+ 10€ / million",
    usagePriceDescription: "additional requests",
    basePriceId: "price_1L9q8aFTCLTZN3milvU3t8xi",
    usagePriceId: "price_1L9q91FTCLTZN3miLQlSxgza",
  },
  {
    id: 2,
    title: "Scale",
    description:
      "For more complex needs with a scalable pricing model and basic analytics",
    basePrice: "89€ / mo",
    basePriceDescription: "5m included requests per month",
    usagePrice: "+ 8€ / million",
    usagePriceDescription: "additional requests",
    basePriceId: "price_1L9qEXFTCLTZN3mi92ipV9vo",
    usagePriceId: "price_1L9qF5FTCLTZN3miOiynvM1b",
  },
  {
    id: 3,
    title: "dApp",
    description:
      "Perfect for bigger projects and dApps with a growing user base. Explore advanced Analytics and invite team members",
    basePrice: "499€ / mo",
    basePriceDescription: "30m included requests per month",
    usagePrice: "+ 6€ / million",
    usagePriceDescription: "additional requests",
    basePriceId: "price_1L9tCUFTCLTZN3mi1eaZwbtp",
    usagePriceId: "price_1L9tDDFTCLTZN3miwvrZPP9U",
  },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

function PricingPlans() {
  const stripe = useStripe();
  const [selectedPricingTier, setSelectedPricingTier] = useState(
    pricingTiers[1]
  );

  const products = [
    {
      price: selectedPricingTier.usagePriceId,
    },
    {
      price: selectedPricingTier.basePriceId,
      quantity: 1,
    },
  ];

  const handleClick = async () => {
    const body = { line_items: products };

    const { id: sessionId } = await fetchFromAPI("checkout", "POST", body);

    const stripeResponse = await stripe?.redirectToCheckout({
      sessionId,
    });

    if (stripeResponse && stripeResponse.error) {
      console.log(stripeResponse.error);
    }
  };

  return (
    <div>
      <RadioGroup value={selectedPricingTier} onChange={setSelectedPricingTier}>
        <RadioGroup.Label className="text-base font-medium text-gray-900 dark:text-gray-100">
          Select your plan
        </RadioGroup.Label>

        <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4 ">
          {pricingTiers.map((pricingTier) => (
            <RadioGroup.Option
              key={pricingTier.id}
              value={pricingTier}
              className={({ checked, active }) =>
                classNames(
                  checked
                    ? "border-transparent"
                    : "border-gray-300 dark:border-gray-700",
                  active
                    ? "border-indigo-500 ring-2 ring-indigo-500 dark:border-highlight-dark dark:ring-highlight-dark"
                    : "",
                  "relative bg-white dark:bg-dark-800 border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none transition duration-200"
                )
              }
            >
              {({ checked, active }) => (
                <>
                  <span className="flex-1 flex">
                    <span className="flex flex-col">
                      <RadioGroup.Label
                        as="span"
                        className="text-2xl leading-6 font-bold text-gray-900 dark:text-gray-100"
                      >
                        {pricingTier.title}
                      </RadioGroup.Label>
                      <RadioGroup.Description
                        as="span"
                        className="mt-4 flex items-center text-sm text-gray-500 dark:text-gray-300"
                      >
                        {pricingTier.description}
                      </RadioGroup.Description>
                      <RadioGroup.Description
                        as="span"
                        className="mt-14 text-2xl font-bold text-gray-900 dark:text-gray-100"
                      >
                        {pricingTier.basePrice}
                        <span
                          className={
                            "flex items-center text-sm font-medium text-gray-500 dark:text-gray-300"
                          }
                        >
                          {pricingTier.basePriceDescription}
                        </span>
                      </RadioGroup.Description>
                      <RadioGroup.Description
                        as="span"
                        className="mt-4 text-xl font-medium text-gray-500 dark:text-gray-400"
                      >
                        {pricingTier.usagePrice}
                        <span
                          className={
                            "flex items-center text-sm text-gray-500 dark:text-gray-400"
                          }
                        >
                          {pricingTier.usagePriceDescription}
                        </span>
                      </RadioGroup.Description>
                    </span>
                  </span>
                  <CheckCircleIcon
                    className={classNames(
                      !checked ? "invisible" : "",
                      "h-5 w-5 text-indigo-600 dark:text-highlight-dark"
                    )}
                    aria-hidden="true"
                  />
                  <span
                    className={classNames(
                      active ? "border" : "border-2",
                      checked
                        ? "border-indigo-500 : dark:border-highlight-dark"
                        : "border-transparent",
                      "absolute -inset-px rounded-lg pointer-events-none transition duration-200"
                    )}
                    aria-hidden="true"
                  />
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
      <div className="relative bg-white dark:bg-dark-800 border rounded-lg shadow-sm flex focus:outline-none mt-12 text-gray-500 dark:text-gray-300 dark:border-gray-700">
        {" "}
        <div className="p-4 ">
          <h3 className="text-lg leading-6 font-medium ">
            Need something custom?
          </h3>
          <div className="mt-2  text-sm">
            <p>
              You have large number of requests and/or have special
              requirements? We offer a bespoke managed node service with
              unlimited requests.
            </p>
          </div>
          <div className="mt-5">
            <button
              type="button"
              className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm font-medium rounded-md bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm
                      transition duration-200 dark:focus:ring-highlight-dark  dark:bg-dark-500 dark:hover:bg-dark-400 dark:border-gray-700
              "
            >
              Contact us on Telegram
            </button>
          </div>
        </div>
      </div>
      <button
        type="submit"
        onClick={handleClick}
        className="bg-indigo-600 dark:bg-dark-button border border-transparent rounded-md shadow-sm py-3 px-20 text-base font-medium text-white hover:bg-indigo-700 dark:hover:bg-dark-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500 dark:focus:bg-highlight-dark dark:border-gray-700 mt-6 flex justify-end transition duration-200"
      >
        Checkout
      </button>
    </div>
  );
}

export default PricingPlans;
