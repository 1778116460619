import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useRef, useState } from "react";
import { SparklesIcon } from "@heroicons/react/outline";
import { useNavigate } from "react-router";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../../../services/firebase";

const chains = [
  {
    id: 0,
    pretty: "WAX Mainnet",
    slug: "WAX",
  },
];
function ProjectSetup(props: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  pid: string;
}) {
  const [projectName, setProjectName] = useState("");
  const [chain, setChain] = useState("WAX");

  const cancelButtonRef = useRef(null);
  const navigate = useNavigate();
  const routeChange = () => {
    const path = `/account/projects`;
    navigate(path);
  };

  const handleSave = () => {
    const projectRef = doc(db, "projects", props.pid);
    setDoc(projectRef, {chain: chain, name: projectName}, {merge: true}).then(r => props.setOpen(false));

  };

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => null}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 dark:bg-gray-800 bg-opacity-75 dark:bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white dark:bg-dark-800 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100 dark:bg-green-600">
                    <SparklesIcon
                      className="h-6 w-6 text-green-600 dark:text-gray-200 "
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100"
                    >
                      Welcome to your new Project
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500 dark:text-gray-300">
                        Start by entering some additional information about your
                        project
                      </p>
                    </div>
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium text-gray-700 dark:text-gray-200 mt-6"
                    >
                      Project Name
                    </label>
                    <input
                      type="text"
                      name="project-name"
                      id="project-name"
                      placeholder="My Awesome Project"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-dark-500 dark:border-gray-700 dark:text-gray-100 dark:placeholder:text-gray-400 transition dark:focus:ring-highlight-dark"
                      onChange={({ target: { value } }) =>
                        setProjectName(value)
                      }
                    />

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="country"
                        className="block text-sm font-medium text-gray-700 dark:text-gray-200 mt-6"
                      >
                        Blockchain
                      </label>
                      <select
                        id="chain"
                        name="chain"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-dark-500 dark:border-gray-700 dark:text-gray-100 dark:placeholder:text-gray-400 transition dark:focus:ring-highlight-dark"
                        onChange={({ target: { value } }) =>
                          setChain(chains[(value as unknown) as number].slug)
                        }
                      >
                        {chains.map((chain) => {
                          return (
                            <option key={chain.slug} value={chain.id}>
                              {chain.pretty}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 dark:bg-dark-button text-base font-medium text-white hover:bg-indigo-700 dark:hover:bg-highlight-dark disabled:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:focus:ring-highlight-dark sm:col-start-2 sm:text-sm transition"
                    disabled={projectName.length < 2 || projectName.length > 20}
                    onClick={handleSave}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:focus:ring-highlight-dark sm:mt-0 sm:col-start-1 sm:text-sm dark:bg-dark-400 dark:text-gray-100 dark:border-gray-700 dark:hover:bg-dark-600 transition"
                    onClick={routeChange}
                    ref={cancelButtonRef}
                  >
                    Go Back
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default ProjectSetup;
