import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signInWithRedirect,
  signOut,
} from "firebase/auth";
import { GoogleAuthProvider } from "firebase/auth";
import {auth, db} from "../services/firebase";
import {doc, setDoc} from "firebase/firestore";

// export let isLoggedIn = true;
// export let user: User
onAuthStateChanged(auth, async (user) => {
    if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User


        await setDoc(doc(db, "users", user.uid), {
            name: user.displayName,
            email: user.email,
            uid: user.uid
        }, {merge: true})

            // authProvider: "google",

        // ...
    } else {
        // User is signed out
        // ...
    }
});

// export const handleLogout = () => {
//     sessionStorage.removeItem('Auth Token');
//     auth.signOut().then(x => console.log("succes " + x)).catch(e => console.log("error " + e));
// }

export const signInWithGoogle = async () => {
  try {
    const googleAuthProvider = new GoogleAuthProvider();
    signInWithRedirect(auth, googleAuthProvider);
  } catch (err) {
    console.error(err);
  }
};

export const signUpWithPassword = async (email: string, password: string) => {
  createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // Signed in
      // const user = userCredential.user;
      // ...
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage);
      // ..
    });
};

export const logout = () => {
  signOut(auth);
};
