import React from "react";
import SignIn from "./forms/signIn";

function Login() {
  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-50">
        <body class="h-full">
        ```
      */}
      <div>
        <SignIn />
      </div>
    </>
  );
}

export default Login;
