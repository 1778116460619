import React, { SVGProps } from "react";

export const AtomicSVG: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      id="validationIcon"
      viewBox="0 0 24 24"
      {...props}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M8.98997 2.87696C6.57484 1.96622 4.49388 1.98317 3.33352 3.14353C1.04511 5.43194 3.20385 11.3009 8.1552 16.2523C13.1066 21.2036 18.9755 23.3624 21.264 21.074C23.5524 18.7856 21.3936 12.9166 16.4423 7.96522C15.985 7.50795 15.5199 7.07449 15.0505 6.66616"
          stroke="current"
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle cx="12" cy="12" r="1.51311" fill="current" />
        <path
          d="M8.98672 21.3418C6.57297 22.2513 4.49336 22.2338 3.33352 21.074C1.04511 18.7856 3.20385 12.9166 8.15521 7.96522C13.1066 3.01386 18.9755 0.855122 21.264 3.14353C23.5524 5.43194 21.3936 11.3009 16.4423 16.2523C15.9626 16.7319 15.4744 17.1853 14.9816 17.6111"
          stroke="current"
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="24" height="24" fill="current" />
        </clipPath>
      </defs>
    </svg>
  );
};
