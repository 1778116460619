import React from "react";
import { useNavigate } from "react-router";
import { FireIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { PlusIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import { useGetProjects } from "../../../hooks/useGetProjects";
import { Loader } from "../../../components/common/Loader";

function ProjectList() {
  const { projects, loading } = useGetProjects();

  // const unsubscribe = onSnapshot(q, (querySnapshot) => {
  //     const keys: any[] = [];
  //     querySnapshot.forEach((doc) => {
  //         keys.push({ name: doc.data().name || "Unnamed Project", chain: doc.data().chain || "Setup your project", created: moment(new Date(new Timestamp(doc.data().created.seconds, doc.data().created.nanoseconds).toDate())).format("hh:mm DD.MM.YYYY"), owner: doc.data().owner, pid: doc.id})
  //     });
  //     setProjects(keys)
  // });

  // const handleCreateProject = async () => {
  //   const response = await fetchFromAPI("create-project", "POST", {
  //     nothing: false,
  //   });
  //   console.log(response);
  // };

  const navigate = useNavigate();
  const routeChange = () => {
    const path = `/plans/select`;
    navigate(path);
  };

  return (
    <div>
      <h1 className="text-3xl font-bold dark:text-gray-100">Projects</h1>
      <div className="mt-10">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900 dark:text-gray-100">
              Your Projects
            </h1>
            <p className="mt-2 text-sm text-gray-700 dark:text-gray-300">
              A list of all Projects you are involved with
            </p>
          </div>
          <div
            className={`mt-4 sm:mt-0 sm:ml-16 sm:flex-none ${
              loading ? "hidden" : "flex"
            }`}
          >
            <button
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent dark:hover:bg-dark-400 transition duration-300  dark:bg-gray-700 dark:bg-opacity-60 dark:border dark:text-gray-200 dark:border-gray-500 dark:hover:border-gray-700 bg-black px-4 py-2 text-sm font-medium text-white shadow-sm hover:border-black hover:text-black hover:bg-white sm:w-auto dark:hover:text-gray-100 "
              onClick={routeChange}
              // onClick={handleCreateProject}
            >
              Create Project
            </button>
          </div>
        </div>

        {/* Loading */}
        {loading && (
          <Loader
            count={10}
            height="120px"
            borderRadius="inherit"
            className="shadow mt-5"
            containerClassName="sm:rounded-md"
            complete
          />
        )}

        {/* User has NO projects */}
        {!loading && projects.length === 0 && (
          <div className="text-center mt-4 p-4 pb-6 bg-white dark:bg-dark-600 dark:bg-opacity-60 shadow overflow-hidden sm:rounded-md">
            <svg
              className="mx-auto h-12 w-12 text-gray-400 dark:text-gray-300"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                vectorEffect="non-scaling-stroke"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
              />
            </svg>
            <h3 className="mt-2 text-sm font-medium text-gray-900 dark:text-gray-100">
              No Projects
            </h3>
            <p className="mt-1 text-sm text-gray-500 dark:text-gray-300">
              You don't have any Projects yet. Create your first project 🎉
            </p>
            <div className="mt-6">
              <Link to="/plans/select">
                <button
                  type="button"
                  className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 dark:hover:bg-dark-400 transition duration-300  dark:bg-gray-700 dark:bg-opacity-60 dark:border dark:text-gray-200 dark:border-gray-500 dark:hover:border-gray-700 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                  Create Project
                </button>
              </Link>
            </div>
          </div>
        )}

        {/* User has projects */}
        {!loading &&
          projects.length !== 0 &&
          projects.map((project) => (
            <ul
              className="space-y-3 bg-white shadow dark:bg-dark-600 dark:bg-opacity-60 overflow-hidden sm:rounded-md mt-5"
              key={project.pid}
            >
              <li>
                <Link
                  to={"/account/projects/" + project.pid}
                  className="block hover:bg-gray-50 dark:hover:bg-[#272f3d] py-5 transition duration-200"
                >
                  <div className="flex items-center px-4 py-4 sm:px-6">
                    <div className="min-w-0 flex-1 flex items-center">
                      {/* <div className="flex-shrink-0">*/}
                      {/*    <img className="h-12 w-12 rounded-full" src={"application.applicant.imageUrl"} alt="" />*/}
                      {/* </div>*/}
                      <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                        <div>
                          <p className="text-sm font-medium text-black dark:text-gray-100 truncate">
                            {project.name}
                          </p>
                          <p className="mt-2 flex items-center text-sm text-gray-500 dark:text-gray-300">
                            <span className="truncate">{project.chain}</span>
                          </p>
                        </div>
                        <div className="hidden md:block">
                          <div>
                            <p className="text-sm text-gray-900 dark:text-gray-100">
                              Created {project.created}
                            </p>
                            <p className="mt-2 flex items-center text-sm text-gray-500 dark:text-gray-300">
                              <FireIcon
                                className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                                aria-hidden="true"
                              />
                              {project.plan.charAt(0).toUpperCase() +
                                project.plan.slice(1)}{" "}
                              Plan
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <ChevronRightIcon
                        className="h-5 w-5 text-gray-400 dark:text-gray-200"
                        aria-hidden="true"
                      />
                    </div>
                  </div>
                </Link>
              </li>
            </ul>
            // <li key={project.pid} className="bg-white shadow overflow-hidden px-4 py-4 sm:px-6 sm:rounded-md mt-10">
            // </li>
          ))}
      </div>
    </div>
  );
}

export default ProjectList;
