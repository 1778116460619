import React from "react";
import PrivateRoutes from "./privateRoutes";
import { MasterLayout } from "../layout/masterLayout";
import { BrowserRouter } from "react-router-dom";
import PublicRoutes from "./publicRoutes";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../services/firebase";
import { useColorMode } from "../hooks/useColorMode";

function Routes() {
  const [user, loading] = useAuthState(auth);
  const { colorTheme } = useColorMode();
  return (
    <BrowserRouter>
      {loading ? (
        <div className="dark:bg-dark-700 h-screen w-full grid place-items-center">
          <img
            src={`/imgs/logo-${colorTheme === "dark" ? "light" : "dark"}.png`}
            alt="OneApi logo"
            className="w-48 h-auto"
          />
        </div>
      ) : user ? (
        <>
          <MasterLayout>
            <PrivateRoutes />
          </MasterLayout>
        </>
      ) : (
        <PublicRoutes />
      )}

      {/* <Route path='/error' component={ErrorsPage} />*/}
      {/* <Route path='/logout' component={Logout} />*/}
    </BrowserRouter>
  );
}

export default Routes;
