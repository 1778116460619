import React, { Fragment, useState } from "react";
import { Transition } from "@headlessui/react";
import CopyToClipboard from "react-copy-to-clipboard";
import { CheckCircleIcon, ClipboardCopyIcon } from "@heroicons/react/solid";

interface Props {
  text: string;
  styling?: string;
}

let timeoutId: NodeJS.Timeout | null = null;

export const ClickToCopy: React.FC<Props> = ({ children, text, styling }) => {
  const [display, setDisplay] = useState(false);
  const [hasCopied, setHasCopied] = useState(false);

  const onCopy = () => {
    if (timeoutId) clearTimeout(timeoutId);
    setHasCopied(true);
    timeoutId = setTimeout(() => {
      setHasCopied(false);
    }, 1000);
  };

  return (
    <span
      onMouseEnter={() => {
        setHasCopied(false);
        setDisplay(true);
      }}
      onMouseLeave={() => {
        setDisplay(false);
        if (timeoutId) clearTimeout(timeoutId);
      }}
    >
      <CopyToClipboard onCopy={onCopy} text={text}>
        <span className={"relative grid cursor-pointer " + styling}>
          {/* Children components */}
          {children}

          {/* Tooltip */}
          <Transition
            as={Fragment}
            show={display}
            enter="transition-opacity duration-75"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="absolute bg-[#333] dark:bg-dark-400 -top-5 m-auto text-white justify-self-center justify-center items-center px-2 py-1 rounded-lg flex pointer-events-none">
              {hasCopied ? (
                <CheckCircleIcon className="w-4 h-4 text-green-400" />
              ) : (
                <ClipboardCopyIcon className="w-4 h-4" />
              )}

              <span className="ml-1">
                {hasCopied ? "Copied!" : "Click to copy"}
              </span>
            </div>
          </Transition>
        </span>
      </CopyToClipboard>
    </span>
  );
};
