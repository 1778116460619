import React from "react";
import ChartArea from "../../components/charts/chartArea";

function Usage() {
  const stats = [
    { name: "Total requests", stat: "12,256,211" },
    { name: "Unique IPs", stat: "35,354" },
    { name: "Average Requests per Second", stat: "4,7/s" },
  ];

  return (
    <div className="text-gray-900 dark:text-gray-100">
      <h1 className="text-3xl font-bold">
        Usage and Stats{" "}
        <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-indigo-100 dark:bg-dark-button text-indigo-800 dark:text-blue-100">
          <svg
            className="-ml-0.5 mr-1.5 h-2 w-2 text-indigo-400 dark:text-blue-100"
            fill="currentColor"
            viewBox="0 0 8 8"
          >
            <circle cx={4} cy={4} r={3} />
          </svg>
          Coming soon!
        </span>
      </h1>

      <div>
        <h3 className="mt-10 text-lg leading-6 font-medium ">
          Summary last 30 days
        </h3>
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
          {stats.map((item) => (
            <div
              key={item.name}
              className="px-4 py-5 bg-white dark:bg-dark-600 dark:bg-opacity-60 shadow rounded-lg overflow-hidden sm:p-6"
            >
              <dt className="text-sm font-medium text-gray-500 dark:dark:first-line:text-gray-200 truncate">
                {item.name}
              </dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900 dark:text-gray-100 blur-sm">
                {item.stat}
              </dd>
            </div>
          ))}
        </dl>
        <h3 className="mt-10 text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">
          Stats last 30 days
        </h3>
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
          <div
            key="chart-1"
            className="px-4 py-5 bg-white dark:bg-dark-600 dark:bg-opacity-60 shadow rounded-lg overflow-hidden sm:p-6"
          >
            <dt className="text-sm font-medium text-gray-500 dark:first-line:text-gray-200 truncate">
              All Api Types
            </dt>
            <div className={"blur-md"}>
              <ChartArea />
            </div>
          </div>
          <div
            key="chart-1"
            className="px-4 py-5 bg-white dark:bg-dark-600 dark:bg-opacity-60 shadow rounded-lg overflow-hidden sm:p-6"
          >
            <dt className="text-sm font-medium text-gray-500 dark:first-line:text-gray-200 truncate">
              Chain
            </dt>
            <div className={"blur-md"}>
              <ChartArea />
            </div>
          </div>
          <div
            key="chart-1"
            className="px-4 py-5 bg-white dark:bg-dark-600 dark:bg-opacity-60 shadow rounded-lg overflow-hidden sm:p-6"
          >
            <dt className="text-sm font-medium text-gray-500 dark:first-line:text-gray-200 truncate">
              Wallet
            </dt>
            <div className={"blur-md"}>
              <ChartArea />
            </div>
          </div>
          <div
            key="chart-1"
            className="px-4 py-5 bg-white dark:bg-dark-600 dark:bg-opacity-60 shadow rounded-lg overflow-hidden sm:p-6"
          >
            <dt className="text-sm font-medium text-gray-500 dark:first-line:text-gray-200 truncate">
              History v1
            </dt>
            <div className={"blur-md"}>
              <ChartArea />
            </div>
          </div>
          <div
            key="chart-1"
            className="px-4 py-5 bg-white dark:bg-dark-600 dark:bg-opacity-60 shadow rounded-lg overflow-hidden sm:p-6"
          >
            <dt className="text-sm font-medium text-gray-500 dark:first-line:text-gray-200 truncate">
              Hyperion v2
            </dt>
            <div className={"blur-md"}>
              <ChartArea />
            </div>
          </div>
          <div
            key="chart-1"
            className="px-4 py-5 bg-white dark:bg-dark-600 dark:bg-opacity-60 shadow rounded-lg overflow-hidden sm:p-6"
          >
            <dt className="text-sm font-medium text-gray-500 dark:first-line:text-gray-200 truncate">
              Atomic
            </dt>
            <div className={"blur-md"}>
              <ChartArea />
            </div>
          </div>
        </dl>
        {/* <h3 className="mt-10 text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">More Stats</h3>*/}
        {/* <div className="relative block mt-5 w-full border-2 border-gray-300 border-dashed rounded-lg p-24 text-center focus:outline-none">*/}
        {/*    <CollectionIcon className=" mx-auto h-12 text-gray-400" aria-hidden="true" />*/}
        {/*    <span className="mt-2 block text-sm font-medium text-gray-900 dark:text-gray-100">More detailed stats coming soon...</span>*/}
        {/* </div>*/}
      </div>
    </div>
  );
}

export default Usage;
