import React, { Fragment, SVGProps, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  ChartBarIcon,
  HomeIcon,
  CreditCardIcon,
  MenuAlt2Icon,
  ChatAlt2Icon,
  BeakerIcon,
  BellIcon,
  SunIcon,
  MoonIcon,
  LogoutIcon,
  ExternalLinkIcon,
} from "@heroicons/react/outline";
import { useAuthState } from "react-firebase-hooks/auth";
import { logout } from "../helpers/authentication";
import { auth } from "../services/firebase";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { useColorMode } from "../hooks/useColorMode";
import { Loader } from "../components/common/Loader";

const navigation: INavItem[] = [
  {
    name: "Dashboard",
    href: "/account/dashboard",
    icon: HomeIcon,
  },
  {
    name: "Projects",
    href: "/account/projects",
    icon: BeakerIcon,
  },
  {
    name: "Usage",
    href: "/account/usage",
    icon: ChartBarIcon,
  },
  {
    name: "Billing",
    href: "/account/billing",
    icon: CreditCardIcon,
  },
  {
    name: "Support",
    href: "/account/support",
    icon: ChatAlt2Icon,
  },
];
// const userNavigation = [
//   { name: "Your Profile", href: "#" },
//   { name: "Settings", href: "#" },
//   { name: "Sign out", href: "#" },
// ];
function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export type PageProps = {
  childPage: JSX.Element;
};

interface INavItem {
  name: string;
  href: string;
  icon: (props: SVGProps<SVGSVGElement>) => JSX.Element;
}

interface INavElement {
  active: string;
  inactive: string;
  default: string;
}

interface NavLinkProps {
  item: INavItem;
  text: INavElement;
  icon: INavElement;
  isActive: boolean;
}

export const MasterLayout: React.FC = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [user, loading] = useAuthState(auth);

  const { colorTheme, setColorMode } = useColorMode();

  const location = useLocation();
  const path = location.pathname;

  const NavLink: React.FC<NavLinkProps> = ({ item, text, icon, isActive }) => {
    return (
      <Link
        key={item.name}
        to={item.href}
        className={classNames(
          isActive ? text.active : text.inactive,
          text.default
        )}
      >
        <item.icon
          className={classNames(
            isActive ? icon.active : icon.inactive,
            icon.default
          )}
          aria-hidden="true"
        />
        {item.name}
      </Link>
    );
  };

  return (
    <div>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-100">
        <body class="h-full">
        ```
      */}
      <div className="flex">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 flex z-40 md:hidden "
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 dark:bg-gray-800 bg-opacity-75 dark:bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 bg-white dark:bg-dark-900">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2" />
                </Transition.Child>
                <div className="px-3">
                  <div className="flex-shrink-0 items-center hidden dark:flex">
                    <img
                      className="h-8 w-auto"
                      src="/imgs/logo-dark.png"
                      alt="oneapi logo"
                    />
                  </div>
                  <div className="flex-shrink-0 items-center flex dark:hidden">
                    <img
                      className="h-8 w-auto"
                      src="/imgs/logo-light.png"
                      alt="oneapi logo"
                    />
                  </div>
                </div>
                <div className="mt-5 flex-1 flex flex-col overflow-y-auto h-full ">
                  <nav className="px-2 space-y-1 flex flex-col justify-center align-center flex-1">
                    {navigation.map((item) => (
                      <NavLink
                        item={item}
                        key={item.name}
                        text={{
                          active: "bg-dark-button text-gray-100",
                          inactive:
                            "text-gray-600 hover:bg-gray-50 dark:hover:bg-[#013d4d] hover:text-gray-900 dark:hover:text-gray-100",
                          default:
                            "group flex items-center px-2 py-2 text-base font-medium rounded-md transition duration-300",
                        }}
                        icon={{
                          active: "text-gray-100",
                          inactive:
                            "text-gray-500 group-hover:text-gray-500 dark:group-hover:text-gray-200",
                          default:
                            "mr-4 flex-shrink-0 h-6 w-6 transition duration-300",
                        }}
                        isActive={path.includes(item.href)}
                      />
                    ))}
                  </nav>
                  <a href="/" target="_blank" className="block px-8 pb-6 ">
                    <button
                      type="button"
                      className="inline-flex rounded-md bg-white shadow  items-center justify-center py-4 border border-transparent text-base leading-4 font-medium text-gray-500 hover:text-gray-200 dark:text-gray-200 dark:border-transparent border-gray-300 hover:gray-100 dark:bg-dark-600 dark:bg-opacity-70 hover:bg-highlight-dark dark:hover:bg-opacity-100 focus:outline-none transition w-full"
                    >
                      <ExternalLinkIcon
                        className="-ml-0.5 mr-2 h-4 w-4"
                        aria-hidden="true"
                      />
                      Documentation
                    </button>
                  </a>
                </div>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden md:flex md:min-w-[260px] md:w-1/5 max-w-120 md:flex-col sticky top-0  md:inset-y-0 z-40 h-screen ">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex flex-col flex-grow border-r border-gray-200 dark:border-gray-800 pt-5 bg-white dark:bg-dark-900 overflow-y-auto">
            <div className="px-[2.1rem]">
              <div className="items-center flex-shrink-0 hidden dark:flex">
                <img
                  className="h-10 w-auto"
                  src="/imgs/logo-dark.png"
                  alt="oneapi logo"
                />
              </div>
              <div className="items-center flex-shrink-0 flex dark:hidden">
                <img
                  className="h-10 w-auto"
                  src="/imgs/logo-light.png"
                  alt="oneapi logo"
                />
              </div>
            </div>
            <div className="flex-grow flex flex-col">
              <nav className="my-auto px-6 space-y-1 flex flex-col justify-center align-center">
                {navigation.map((item) => (
                  <NavLink
                    item={item}
                    key={item.name}
                    text={{
                      active: "text-highlight-dark font-extrabold",
                      inactive: "text-gray-400 font-bold hover:text-[#226573]",
                      default:
                        "group flex items-center px-2 py-2 text-xl font-medium rounded-md transition duration-200",
                    }}
                    icon={{
                      active: "text-highlight-dark font-extrabold",
                      inactive: "text-gray-400 group-hover:text-[#226573]",
                      default:
                        "mr-3 flex-shrink-0 h-6 w-6 transition duration-200",
                    }}
                    isActive={path.includes(item.href)}
                  />
                ))}
              </nav>
              <a href="/" target="_blank" className="block px-8 pb-6 ">
                <button
                  type="button"
                  className="inline-flex rounded-md border-t-gray-100 items-center justify-center py-5 border border-transparent shadow text-lg leading-4 font-medium text-gray-500 hover:text-gray-200 dark:text-gray-200 dark:border-transparent  hover:gray-100 dark:bg-dark-600 dark:bg-opacity-70 hover:bg-highlight-dark dark:hover:bg-opacity-100 focus:outline-none transition w-full"
                >
                  <ExternalLinkIcon
                    className="-ml-0.5 mr-2 h-4 w-4"
                    aria-hidden="true"
                  />
                  Documentation
                </button>
              </a>
            </div>
          </div>
        </div>
        <div className=" flex flex-col flex-1 dark:bg-dark-700">
          {/* Top Bar */}
          <div className="sticky top-0 z-10 flex-shrink-0 blur-blue flex h-16 items-center shadow transition duration-200">
            <div className="ml-4 flex items-center md:ml-auto mr-8 w-full">
              <button
                type="button"
                className="px-4 border-r border-gray-200 text-gray-500  focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
                onClick={() => setSidebarOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
              </button>
              <div className="flex flex-1 justify-end">
                <button
                  type="button"
                  className="max-w-xs border-2 dark:bg-opacity-20 dark:bg-dark-900 dark:border-dark-button dark:hover:bg-dark-button dark:hover:border-dark-button flex items-center text-sm rounded-full focus:outline-none focus:ring-2 dark:focus:ring-2 focus:ring-offset-2 dark:focus:ring-offset-0 focus:ring-indigo-500 dark:focus:border-dark-900 dark:focus:ring-highlight-dark transition p-1"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon
                    className="h-6 w-6 text-gray-700 dark:text-gray-400 dark:hover:text-gray-100"
                    aria-hidden="true"
                  />
                </button>

                {/* Profile dropdown */}
                <Menu as="div" className="ml-3 relative">
                  <div>
                    <Menu.Button className="max-w-x flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 dark:focus:ring-offset-0 focus:ring-indigo-500 dark:focus:ring-highlight-dark transition leading-4 w-9 h-9">
                      <span className="sr-only">Open user menu</span>

                      {loading && (
                        <Loader
                          width="2.25rem"
                          height="2.25rem"
                          circle={true}
                        />
                      )}

                      {!loading && user && user.photoURL && (
                        <img
                          src={user.photoURL || ""}
                          alt=""
                          className="h-9 w-9 rounded-full"
                          referrerPolicy="no-referrer"
                        />
                      )}

                      {/* Initials Avatar */}
                      {/* 
                      <span className="inline-flex items-center justify-center h-8 w-8 rounded-full ">
                        <span className="text-sm font-medium leading-none text-gray-900 dark:text-gray-100">
                          {user.displayName
                            ? user.displayName
                              .split(" ")
                              .map((n) => n[0])
                              .join("")
                          : "U"}
                      </span>
                    </span>
                    */}
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute overflow-hidden right-0 mt-2 w-48 rounded-md shadow-lg pt-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-dark-500 divide-y divide-slate-300 dark:divide-gray-900">
                      <div className="px-4 py-3">
                        <p className="text-sm text-gray-700 dark:text-gray-300">
                          Signed in as
                        </p>
                        <p className="text-sm font-medium text-gray-900 dark:text-gray-100 truncate">
                          {user?.displayName || "User"}
                        </p>
                      </div>
                      <Menu.Item>
                        <div
                          className="flex items-center justify-between py-2 dark:hover:bg-gray-600 hover:bg-gray-200 cursor-pointer transition duration-200"
                          onClick={() => setColorMode(colorTheme)}
                        >
                          <p className="block pl-4 text-sm  text-gray-700 dark:text-gray-100">
                            {colorTheme === "dark" ? "Dark mode" : "Light mode"}
                          </p>
                          <span className="text-gray-100 pr-4">
                            {colorTheme === "dark" ? (
                              <MoonIcon className="w-5 h-5 text-gray-700" />
                            ) : (
                              <SunIcon className="w-5 h-5" />
                            )}
                          </span>
                        </div>
                      </Menu.Item>
                      <Menu.Item>
                        <div
                          className="flex items-center justify-between py-3.5 bg-[#e4e3e3] dark:bg-dark-600 dark:hover:bg-gray-600 hover:bg-gray-200 cursor-pointer transition duration-200"
                          onClick={logout}
                        >
                          <a
                            href="/"
                            className="block px-4 text-sm text-gray-900 dark:text-gray-100"
                          >
                            Sign out
                          </a>
                          <span className="text-gray-900 dark:text-gray-100 w-5 h-5 mr-3">
                            <LogoutIcon />
                          </span>
                        </div>
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <main className="flex-1 dark:bg-dark-700 min-h-screen transition duration-300">
            <div className="py-6">
              <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 ">
                {children}
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};
