import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { ExclamationIcon } from "@heroicons/react/solid";
import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import { db } from "../../../../services/firebase";
import DeleteApiKey from "./deleteApiKey";

interface IApiKey {
  id: string;
  key: string;
  created: Date;
  accessAtomic: boolean;
  accessChain: boolean;
  accessHistoryV1: boolean;
  accessHistoryV2: boolean;
  blocked: boolean;
}

interface Props {
  open: boolean;
  close: () => void;
  apiKey: IApiKey;
}

const EditApiKey: React.FC<Props> = ({ open, close, apiKey }) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [options, setOptions] = useState({
    chain: false,
    history: false,
    hyperion: false,
    atomic: false,
  });

  const updateOptions = useCallback(() => {
    const {
      accessAtomic,
      accessChain,
      accessHistoryV1,
      accessHistoryV2,
    } = apiKey;
    setOptions({
      chain: accessChain,
      history: accessHistoryV1,
      hyperion: accessHistoryV2,
      atomic: accessAtomic,
    });
  }, [apiKey]);

  const validation = () => {
    return Object.values(options).every((option) => !option);
  };

  useEffect(() => {
    updateOptions();
  }, [apiKey, updateOptions]);

  const deleteKey = async () => {
    await deleteDoc(doc(db, "api-keys", apiKey.id));
    close();
  };

  const updateKey = async () => {
    await updateDoc(doc(db, "api-keys", apiKey.id), {
      access_chain: options.chain,
      access_history_v1: options.history,
      access_history_v2: options.hyperion,
      access_atomic: options.atomic,
    });
  };

  return (
    <>
      <DeleteApiKey
        close={() => setDeleteModal(false)}
        open={deleteModal}
        deleteKey={deleteKey}
      />

      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-1000"
          onClose={() => {
            if (!deleteModal) {
              close();
              updateOptions();
            }
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed z-40 inset-0 bg-gray-500 dark:bg-gray-800 bg-opacity-75 dark:bg-opacity-75 transition-opacity w-screen" />
          </Transition.Child>
          {!deleteModal && (
            <div className="fixed z-40 inset-0 overflow-y-auto">
              <div className="flex items-center justify-center min-h-full text-center sm:p-0">
                <Transition.Child
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative bg-white dark:bg-dark-800 rounded-lg text-left overflow-hidden shadow-xl transform transition-all w-screen max-w-[520px]">
                    <div>
                      <main className="relative">
                        <div className="mx-auto">
                          <div className="bg-white dark:bg-dark-800 rounded-lg shadow overflow-hidden">
                            <div className="block absolute top-0 right-0 pt-4 pr-5">
                              <button
                                type="button"
                                className="bg-white rounded-md text-gray-400 dark:bg-transparent dark:text-gray-200 dark:hover:text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:focus:ring-transparent"
                                onClick={() => {
                                  close();
                                  updateOptions();
                                }}
                              >
                                <span className="sr-only">Close</span>
                                <XIcon className="h-6 w-6" aria-hidden="true" />
                              </button>
                            </div>
                            <div className="divide-y divide-gray-200 dark:divide-dark-500">
                              <div className="lg:col-span-9 flex flex-col overflow-y-auto sm:w-auto">
                                <div className="flex-1">
                                  {/* API Key Config */}
                                  <div className="px-2 sm:px-6 py-3 divide-y divide-gray-200 dark:divide-dark-500">
                                    <div className="pt-5 pb-4">
                                      <div className="mb-2">
                                        <h2 className="text-lg leading-6 font-bold dark:text-gray-300">
                                          Restrict API Key
                                        </h2>
                                        <p className="mt-1 text-sm mr-4  text-gray-500 dark:text-gray-300">
                                          Restrict an API key to certain
                                          categories in order to prevent
                                          unwanted requests
                                        </p>
                                      </div>
                                      <div>
                                        <div className="grid grid-cols-12 gap-6">
                                          <div className="col-span-12 mt-2">
                                            <div>
                                              <div className="flex justify-between items-center">
                                                <div className="flex flex-grow mt-1 md:mt-0 mr-4">
                                                  <p className="text-sm dark:text-gray-200">
                                                    Chain API
                                                  </p>
                                                </div>
                                                <div className="flex-shrink-0">
                                                  <input
                                                    id="key-restricted"
                                                    aria-describedby="key-restricted"
                                                    name="key-restricted"
                                                    type="checkbox"
                                                    checked={options.chain}
                                                    onChange={() => {
                                                      setOptions({
                                                        ...options,
                                                        chain: !options.chain,
                                                      });
                                                    }}
                                                    className="h-4 w-4 border-gray-300 dark:bg-dark-500 dark:border-gray-700 dark:hover:bg-dark-400 dark:text-gray-200 rounded dark:checked:bg-dark-700 focus:ring-0 cursor-pointer"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="grid grid-cols-12 gap-6">
                                          <div className="col-span-12 mt-4">
                                            <div className="flex items-center justify-between">
                                              <label
                                                htmlFor="project-name"
                                                className="text-sm font-medium text-gray-700 dark:text-gray-300 mr-4"
                                              >
                                                History API
                                              </label>
                                              <input
                                                id="key-restricted"
                                                aria-describedby="key-restricted"
                                                name="key-restricted"
                                                type="checkbox"
                                                checked={options.history}
                                                onChange={() => {
                                                  setOptions({
                                                    ...options,
                                                    history: !options.history,
                                                  });
                                                }}
                                                className="h-4 w-4 border-gray-300 dark:bg-dark-500 dark:border-gray-700 dark:hover:bg-dark-400 dark:text-gray-200 rounded dark:checked:bg-dark-700 focus:ring-0 cursor-pointer"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="grid grid-cols-12 gap-6">
                                          <div className="col-span-12 mt-4">
                                            <div className="flex items-center justify-between">
                                              <label
                                                htmlFor="project-name"
                                                className="text-sm font-medium text-gray-700 dark:text-gray-300 mr-4"
                                              >
                                                Hyperion API
                                              </label>
                                              <input
                                                id="key-restricted"
                                                aria-describedby="key-restricted"
                                                name="key-restricted"
                                                type="checkbox"
                                                checked={options.hyperion}
                                                onChange={() => {
                                                  setOptions({
                                                    ...options,
                                                    hyperion: !options.hyperion,
                                                  });
                                                }}
                                                className="h-4 w-4 border-gray-300 dark:bg-dark-500 dark:border-gray-700 dark:hover:bg-dark-400 dark:text-gray-200 rounded dark:checked:bg-dark-700 focus:ring-0 cursor-pointer"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="grid grid-cols-12 gap-6">
                                          <div className="col-span-12 mt-4">
                                            <div className="flex items-center justify-between">
                                              <label
                                                htmlFor="project-name"
                                                className="text-sm font-medium text-gray-700 dark:text-gray-300 mr-4"
                                              >
                                                Atomic API
                                              </label>
                                              <input
                                                id="key-restricted"
                                                aria-describedby="key-restricted"
                                                name="key-restricted"
                                                type="checkbox"
                                                checked={options.atomic}
                                                onChange={() => {
                                                  setOptions({
                                                    ...options,
                                                    atomic: !options.atomic,
                                                  });
                                                }}
                                                className="h-4 w-4 border-gray-300 dark:bg-dark-500 dark:border-gray-700 dark:hover:bg-dark-400 dark:text-gray-200 rounded dark:checked:bg-dark-700 focus:ring-0 cursor-pointer"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="pt-4">
                                      <h2 className="text-lg leading-6 font-bold pb-3 dark:text-gray-300">
                                        Danger Zone
                                      </h2>

                                      <div className="px-2 sm:px-3 ring-[1px] ring-[#ff8080] dark:ring-red-700 ring-opacity-75 rounded-md divide-y divide-gray-200 dark:divide-gray-700 text-sm ">
                                        <div className="pt-3 pb-4">
                                          <p className="block font-bold dark:text-gray-300">
                                            Delete API Key
                                          </p>
                                          <div className="flex justify-between items-center">
                                            <div className="flex flex-grow mt-1 md:mt-0 mr-4">
                                              <ExclamationIcon
                                                className="h-5 w-5 text-red-500 dark:text-red-600 mr-2 flex-shrink-0"
                                                aria-hidden="true"
                                              />
                                              <p className="text-sm dark:text-gray-200">
                                                Once you delete a Key, there's
                                                no going back.
                                              </p>
                                            </div>
                                            <div className="flex-shrink-0">
                                              <button
                                                className="mt-1 md:mt-0 w-full border whitespace-nowrap border-red-300 dark:bg-red-700 dark:text-gray-100 dark:border-red-900 rounded-md shadow-sm py-2 px-4 focus:outline-none focus:ring-red-500 focus:border-red-500 text-sm hover:bg-red-700 transition duration-200 hover:text-white"
                                                onClick={() => {
                                                  setDeleteModal(true);
                                                }}
                                              >
                                                Delete this Key
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="mt-4 py-4 px-4 flex justify-end sm:px-6">
                                    <button
                                      type="button"
                                      className="bg-white border border-gray-300 dark:bg-dark-500 dark:border-gray-700 dark:text-gray-100 rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                      onClick={() => {
                                        updateOptions();
                                        close();
                                      }}
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      className="ml-5 bg-indigo-700 dark:bg-dark-button dark:hover:bg-highlight-dark dark:disabled:bg-[#014c5e] border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-gray-400 transition focus:ring-transparent"
                                      onClick={() => {
                                        updateKey();
                                        close();
                                      }}
                                      disabled={validation()}
                                    >
                                      Save
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </main>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          )}
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default EditApiKey;
