import React, { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { ExclamationIcon } from "@heroicons/react/solid";

interface Props {
  open: boolean;
  close: () => void;
  deleteKey: () => void;
}

const DeleteApiKey: React.FC<Props> = ({ open, close, deleteKey }) => {
  const [modalChecked, setModalChecked] = useState(false);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-40 inset-0 overflow-y-auto"
        onClose={() => {
          close();
          setModalChecked(false);
        }}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed z-40 inset-0 transition-opacity w-screen" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative z-40 inline-block align-bottom bg-white dark:bg-dark-800 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 dark:bg-transparent dark:text-gray-200 dark:hover:text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:focus:ring-transparent"
                  onClick={close}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 dark:bg-red-600 sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationIcon
                    className="h-6 w-6 text-red-600 dark:text-red-200"
                    aria-hidden="true"
                  />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100"
                  >
                    Deleting API Key
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500 dark:text-gray-300">
                      Are you sure you want to delete your API key? All future
                      requests with this API key will fail. This action cannot
                      be undone. We recommend only deleting an API key if the
                      Key has been exposed.
                    </p>
                  </div>
                  <div className="relative flex items-start mt-5 mb-2">
                    <div className="flex items-center h-5">
                      <input
                        id="delete-key"
                        aria-describedby="comments-description"
                        name="delete-key"
                        type="checkbox"
                        className="h-4 w-4 border-gray-300 dark:bg-dark-500 dark:border-gray-700 dark:hover:bg-dark-400 dark:text-gray-200 rounded dark:checked:bg-dark-700 focus:ring-0 cursor-pointer"
                        checked={modalChecked}
                        onChange={() => {
                          setModalChecked(!modalChecked);
                        }}
                      />
                    </div>
                    <label
                      className="ml-3 text-sm dark:text-gray-100 cursor-pointer select-none"
                      htmlFor="delete-key"
                    >
                      I understand that deleting my API key will make all future
                      requests with this key fail. This cannot be undone.
                    </label>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  disabled={!modalChecked}
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 disabled:bg-red-900 text-base font-medium text-white hover:bg-red-700 sm:ml-3 sm:w-auto sm:text-sm disabled:cursor-default cursor-pointer dark:disabled:bg-red-900 dark:bg-red-700"
                  onClick={() => {
                    close();
                    deleteKey();
                    setModalChecked(false);
                  }}
                >
                  Delete
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:bg-dark-500 dark:border-gray-700 dark:text-gray-100 shadow-sm px-4 py-2 bg-white hover:bg-gray-50 text-base font-medium text-gray-700 hover:text-gray-500 sm:mt-0 sm:w-auto sm:text-sm "
                  onClick={() => {
                    close();
                    setModalChecked(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default DeleteApiKey;
