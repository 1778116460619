import React, { useEffect, useState } from "react";

export const useColorMode = () => {
  const [colorMode, setColorMode] = useState(
    localStorage.theme ? localStorage.theme : "dark"
  );

  const colorTheme = colorMode === "dark" ? "light" : "dark";

  useEffect(() => {
    const root = window.document.documentElement;

    root.classList.remove(colorTheme);
    root.classList.add(colorMode);

    localStorage.setItem("theme", colorMode);
  }, [colorTheme, colorMode]);

  return { colorTheme, setColorMode };
};
