import ApexCharts from "apexcharts";
import React, { useEffect, useRef } from "react";

function ChartArea() {
  const chartRef = useRef<HTMLDivElement | null>(null);

  const options = {
    chart: {
      type: "area",
      toolbar: false,
    },
    series: [
      {
        name: "sales",
        data: [
          100000,
          200000,
          500000,
          50000,
          200000,
          100000,
          200000,
          500000,
          100000,
          560123,
          15015,
        ],
      },
    ],
    stroke: {
      curve: "smooth",
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: "datetime",
      categories: [
        "2022-03-01",
        "2022-03-02",
        "2022-03-03",
        "2022-03-04",
        "2022-03-05",
        "2022-03-06",
        "2022-03-07",
        "2022-03-08",
        "2022-03-09",
        "2022-03-10",
        "2022-03-11",
      ],
    },
    colors: ["#37b0e3"],
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
  };

  useEffect(() => {
    if (!chartRef.current) {
      return;
    }

    const chart = new ApexCharts(chartRef.current, options);
    if (chart) {
      chart.render();
    }

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [chartRef]);

  return (
    <div ref={chartRef} id="kt_user_chart" style={{ height: "200px" }}></div>
  );
}

export default ChartArea;
