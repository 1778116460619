import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CashIcon } from "@heroicons/react/outline";
import { PaperClipIcon } from "@heroicons/react/solid";
import { CheckCircleIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { useNavigate } from "react-router";
import moment from "moment";
import { useGetProjects } from "../../hooks/useGetProjects";
import { fetchFromAPI } from "../../helpers/fetchApi";
import { NoSubscriptions } from "./noSubscriptions";
import { Loader } from "../../components/common/Loader";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../services/firebase";

const stats = [
  { name: "Unpaid Request", stat: "71,897" },
  { name: "Unpaid amount", stat: "$70" },
  { name: "Due in", stat: "15 Days" },
];

function getStatusStyle(status: string) {
  switch (status) {
    case "paid":
      return "bg-green-100 text-green-800 dark:bg-green-600 dark:text-green-200";
    case "open":
      return "bg-yellow-100 text-yellow-800 dark:bg-yellow-600 dark:text-yellow-100";
    case "draft":
      return "bg-gray-100 text-gray-800 dark:bg-gray-600 dark:text-gray-100";
    case "deleted":
      return "bg-gray-100 text-gray-800 dark:bg-gray-600 dark:text-gray-100";
    default:
      return "bg-red-100 text-red-800";
  }
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

type InvoiceStatus = "paid" | "open" | "draft" | "deleted";

interface Invoice {
  id: string;
  billing_reason: string;
  total: number;
  currency: string;
  status: InvoiceStatus;
  created: number;
  invoice_pdf: string;
}

function Billing() {
  const navigate = useNavigate();
  // todo: change to pull subscriptions from stripe api instead
  const { projects, loading: projectsLoading } = useGetProjects();
  const [invoicesLoading, setInvoicesLoading] = useState(true);
  const [invoices, setInvoices] = useState<{
    data: Invoice[];
    hasMore: boolean;
  }>({ data: [], hasMore: false });

  const [user] = useAuthState(auth);

  const fetchInvoices = useCallback(async () => {
    if (user && user.uid) {
      try {
        const response = await fetchFromAPI("invoices", "POST");
        setInvoices(response);
        setInvoicesLoading(false);
      } catch (e) {
        console.error(e);
        setInvoicesLoading(false);
      }
    }
  }, [user]);

  const handleInvoicePdf = (url: string) => {
    window.open(url);
  };

  const TableHeader: React.FC<{ styling?: string }> = ({
    children,
    styling,
  }) => {
    return (
      <th
        className={`px-6 py-3 bg-gray-50 dark:bg-dark-800  dark:bg-opacity-70 text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider ${styling}`}
      >
        {children}
      </th>
    );
  };

  useEffect(() => {
    fetchInvoices();
  }, [fetchInvoices]);

  return (
    <div>
      <h1 className="text-3xl font-bold dark:text-gray-100">
        Plans and Billing
      </h1>
      <div>
        <h3 className="mt-10 text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">
          Upcoming billing Period
        </h3>
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
          {stats.map((item) => (
            <div
              key={item.name}
              className="px-4 py-5 bg-white dark:bg-dark-600 dark:bg-opacity-60 shadow rounded-lg overflow-hidden sm:p-6"
            >
              <dt className="text-sm font-medium text-gray-500 dark:text-gray-300 truncate">
                {item.name}
              </dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900 dark:text-gray-100">
                {item.stat || <Loader />}
              </dd>
            </div>
          ))}
        </dl>
      </div>

      {/* Subscriptions */}

      <h3 className="mt-10 text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">
        Your Subscriptions
      </h3>

      {/* Subscriptions - Has Subscriptions */}
      {projects && projects.length > 0 && (
        <div>
          <div className="mt-4 bg-white dark:bg-dark-600 dark:bg-opacity-60 shadow overflow-hidden sm:rounded-md">
            <ul className="divide-y divide-gray-200 dark:divide-gray-700">
              {projects.map((project) => (
                <li key={project.pid}>
                  <Link
                    to={"/account/projects/" + project.pid}
                    className="block hover:bg-gray-50 dark:hover:bg-dark-600 transition duration-300"
                  >
                    <div className="flex items-center px-4 py-4 sm:px-6">
                      <div className="min-w-0 flex-1 flex items-center">
                        <div className="flex-shrink-0">
                          <img
                            className="h-12 w-12 rounded-full"
                            src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                            alt=""
                          />
                        </div>
                        <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                          <div>
                            <p className="text-sm font-medium text-indigo-600 dark:text-highlight-dark truncate">
                              {project.chain} {project.plan} Plan
                            </p>
                            <p className="mt-2 flex items-center text-sm text-gray-500 dark:text-gray-300">
                              <span className="truncate font-mono">
                                {project.name}
                              </span>
                            </p>
                          </div>
                          <div className="hidden md:block">
                            <div>
                              <p className="text-sm text-gray-900 dark:text-gray-100">
                                Active since{" "}
                                <time dateTime={project.created}>
                                  {project.created}
                                </time>
                              </p>
                              <p className="mt-2 flex items-center text-sm text-gray-500 dark:text-gray-300">
                                <CheckCircleIcon
                                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                                  aria-hidden="true"
                                />
                                Active
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <ChevronRightIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <button
            type="button"
            className="mt-2 inline-flex items-center justify-center rounded-md bg-transparent px-4 py-2 text-sm font-medium text-gray-400 dark:text-gray-200 hover:bg-gray-50 transition dark:hover:bg-dark-500  hover:shadow-sm sm:w-auto"
            onClick={() => {
              navigate("/plans/select");
            }}
          >
            Add Subscription
            <ChevronRightIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </button>
        </div>
      )}

      {/* Subscriptions - No Subscriptions */}

      {!projectsLoading && projects.length === 0 && <NoSubscriptions />}

      {/* Subscriptions - Loading */}

      {projectsLoading && (
        <div className="min-w0 flex-1 flex items-center bg-white shadow dark:bg-transparent overflow-hidden sm:rounded-md mt-4 mb-10">
          <div className="flex-1 h-full leading-4">
            <Loader height="240px" complete />
          </div>
        </div>
      )}

      {/* Invoices */}

      <h3 className="mt-10 text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">
        Previous Invoices
      </h3>

      <div className="flex flex-col mt-4">
        {/* Invoices - Has Invoices */}

        {!invoicesLoading &&
          invoices &&
          invoices.data &&
          invoices.data.length > 0 && (
            <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
              <div>
                <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                  <thead>
                    <tr>
                      <TableHeader styling="text-left">Description</TableHeader>
                      <TableHeader>Amount</TableHeader>
                      <TableHeader styling="hidden md:block">
                        Status
                      </TableHeader>
                      <TableHeader styling="text-right">Date</TableHeader>
                      <TableHeader>Invoice</TableHeader>
                    </tr>
                  </thead>
                  <tbody className="bg-white dark:bg-dark-600 dark:bg-opacity-60 divide-y divide-gray-200 dark:divide-gray-700">
                    {invoices.data.map((invoice: Invoice) => (
                      <tr key={invoice.id}>
                        <td className="max-w-0 w-full px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-100">
                          <div className="flex">
                            <a className="group inline-flex space-x-2 truncate text-sm">
                              <CashIcon
                                className="flex-shrink-0 h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                              <p className="text-gray-500 dark:text-gray-300 truncate">
                                {invoice.billing_reason}
                              </p>
                            </a>
                          </div>
                        </td>
                        <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
                          <span className="text-gray-900 dark:text-gray-100 font-medium font-mono">
                            {(invoice.total / 100).toFixed(2)}{" "}
                          </span>
                          {invoice.currency}
                        </td>

                        <td className="hidden px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300 md:block">
                          <span
                            className={classNames(
                              getStatusStyle(invoice.status),
                              "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize"
                            )}
                          >
                            {invoice.status}
                          </span>
                        </td>
                        <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
                          {moment(new Date(invoice.created)).format(
                            "MMMM DD, YYYY"
                          )}
                        </td>
                        <td className="text-right px-6 py-4">
                          <button
                            type="button"
                            className="relative inline-flex items-center px-3 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-500 dark:text-gray-300 bg-white hover:bg-gray-50 dark:bg-dark-800 dark:hover:bg-dark-400 transition duration-300 dark:border-gray-700"
                            onClick={() => {
                              handleInvoicePdf(invoice.invoice_pdf);
                            }}
                          >
                            <PaperClipIcon
                              className="-ml-0.5 h-4 w-4"
                              aria-hidden="true"
                            />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/* Pagination */}
                <nav
                  className="bg-white dark:bg-dark-800 dark:bg-opacity-70 dark:border-gray-700 px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
                  aria-label="Pagination"
                >
                  <div className="hidden sm:block">
                    <p className="text-sm text-gray-700 dark:text-gray-300">
                      Showing <span className="font-medium">1</span> to{" "}
                      <span className="font-medium">10</span> of{" "}
                      <span className="font-medium">20</span> results
                    </p>
                  </div>
                  <div className="flex-1 flex justify-between sm:justify-end">
                    <a
                      href="#"
                      className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50  dark:hover:bg-dark-400 transition duration-300  dark:bg-gray-700 dark:bg-opacity-60 dark:border dark:text-gray-200 dark:border-gray-500 dark:hover:border-gray-700"
                    >
                      Previous
                    </a>
                    <a
                      href="#"
                      className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50  dark:hover:bg-dark-400 transition duration-300  dark:bg-gray-700 dark:bg-opacity-60 dark:border dark:text-gray-200 dark:border-gray-500 dark:hover:border-gray-700"
                    >
                      Next
                    </a>
                  </div>
                </nav>
              </div>
            </div>
          )}

        {/* Invoices - No invoices */}
        {!invoicesLoading &&
          (!invoices || !invoices.data || invoices.data.length === 0) && (
            <div className="text-center mt-4 p-4 py-12 bg-white dark:bg-dark-600 dark:bg-opacity-60 shadow overflow-hidden sm:rounded-md">
              <CashIcon
                className=" mx-auto h-12 text-gray-400 dark:text-gray-300"
                aria-hidden="true"
              />
              <span className="mt-2 block text-sm font-medium text-gray-900 dark:text-gray-300">
                There are no previous invoices
              </span>
            </div>
          )}

        {/* Invoices - Loading */}
        {invoicesLoading && (
          <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700 ">
              <thead>
                <tr>
                  <TableHeader styling="text-left">Description</TableHeader>
                  <TableHeader>Amount</TableHeader>
                  <TableHeader styling="hidden md:block">Status</TableHeader>
                  <TableHeader styling="text-right">Date</TableHeader>
                  <TableHeader>Invoice</TableHeader>
                </tr>
              </thead>
              <tbody className="bg-white dark:bg-dark-600 dark:bg-opacity-60 divide-y divide-gray-200 dark:divide-gray-700">
                {Array.from({ length: 5 }).map((_, idx) => (
                  <tr key={idx}>
                    <td className="max-w-0 w-full px-6 py-4 whitespace-nowrap text-sm">
                      <div className="flex">
                        <CashIcon
                          className="flex-shrink-0 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        <p className="ml-2 w-full">
                          <Loader style={{ height: "25px", width: "100%" }} />
                        </p>
                      </div>
                    </td>
                    <td className="px-6 py-4 text-right whitespace-nowrap">
                      <Loader style={{ height: "25px" }} />
                    </td>
                    <td className="hidden px-6 py-4 whitespace-nowrap text-sm md:block">
                      <Loader style={{ height: "25px" }} />
                    </td>
                    <td className="px-6 py-4 text-right whitespace-nowrap text-sm">
                      <Loader style={{ height: "25px", width: "120px" }} />
                    </td>
                    <td className="text-right px-6 py-4">
                      <span className="flex justify-center">
                        <Loader
                          style={{ height: "1.5rem", width: "1.75rem" }}
                        />
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}

export default Billing;
