import React, { SVGProps } from "react";

export const ApiSVG: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      id="validationIcon"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        d="M8 21L4 17L8 13"
        stroke="current"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 17L5 17"
        stroke="current"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 3L20 7L16 11"
        stroke="current"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 7L19 7"
        stroke="current"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
