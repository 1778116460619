import React from "react";
import Skeleton, { SkeletonProps } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

interface LoaderProps extends SkeletonProps {
  // Is the loader the entire component?
  complete?: boolean;
}

export const Loader: React.FC<LoaderProps> = ({
  className,
  complete,
  ...props
}) => {
  return (
    <>
      <span className={"leading-4 hidden dark:inline"}>
        <Skeleton
          {...props}
          baseColor={complete ? "#222a3899" : "#222a38"}
          highlightColor="#36435a"
          className={className}
        />
      </span>

      <span className="dark:hidden ">
        <Skeleton
          {...props}
          baseColor={complete ? "#fcfcfd" : ""}
          highlightColor={complete ? "#f4f4f4" : ""}
          className={className}
        />
      </span>
    </>
  );
};
