import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "../pages/dashboard";
import Usage from "../pages/usage";
import Support from "../pages/support";
import SingleProject from "../pages/projects/single-project";
import ProjectList from "../pages/projects/project-list";
import PricingPlans from "../pages/checkout/pricingPlans";
import CheckoutCanceled from "../pages/checkout/canceled";
import Billing from "../pages/billing";

function PrivateRoutes() {
  // todo: add default redirect
  return (
    <Routes>
      <Route path="account/projects" element={<ProjectList />} />
      <Route path="account/projects/:projectId" element={<SingleProject />} />
      <Route path="account/usage" element={<Usage />} />
      <Route path="account/billing" element={<Billing />} />
      <Route path="plans/select" element={<PricingPlans />} />
      <Route path="account/support" element={<Support />} />
      <Route path="account/dashboard" element={<Dashboard />} />
      <Route path="checkout/canceled" element={<CheckoutCanceled />} />
      {/* <Route path="/login" element={<Navigate replace to="/account/dashboard" />} />*/}
    </Routes>
  );
}

export default PrivateRoutes;
