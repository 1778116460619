import { auth } from "../services/firebase";

const API = "https://api.dashboard.oneapi.dev";

/**
 * A helper function to fetch data from your API.
 */
export async function fetchFromAPI(
  endpointURL: any,
  method = "POST",
  body: any = null
) {
  // const { method, body } = { method: 'POST', body: null, ...opts };

  const user = auth.currentUser;

  const token = user && (await user.getIdToken());

  const res = await fetch(`${API}/${endpointURL}`, {
    method,
    ...(body && { body: JSON.stringify(body) }),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return res.json();
}
