import React, { useCallback, useEffect } from "react";
import {
  CheckCircleIcon,
  ExclamationIcon,
  MinusCircleIcon,
} from "@heroicons/react/solid";
import { useState } from "react";
import { KeyIcon, UserGroupIcon } from "@heroicons/react/outline";
import { useParams } from "react-router";
import { ApiSVG } from "./assets/apiSVG";

import {
  collection,
  query,
  where,
  Timestamp,
  doc,
  onSnapshot,
  DocumentData,
} from "firebase/firestore";
import moment from "moment";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { fetchFromAPI } from "../../../helpers/fetchApi";
import ProjectSetup from "./modals/projectSetup";
import { FirebaseError } from "firebase/app";
import ProjectError from "./modals/projectError";
import { db } from "../../../services/firebase";
import { EditProject } from "./modals/editProject";
import { ClickToCopy } from "./clickToCopy";
import { HistorySVG } from "./assets/historySVG";
import { HyperionSVG } from "./assets/hyperionSVG";
import { AtomicSVG } from "./assets/atomicSVG";
import { Loader } from "../../../components/common/Loader";
import EditApiKey from "./modals/editApiKey";

interface IApiKey {
  id: string;
  key: string;
  created: Date;
  accessAtomic: boolean;
  accessChain: boolean;
  accessHistoryV1: boolean;
  accessHistoryV2: boolean;
  blocked: boolean;
}

function SingleProject() {
  // Firebase objects
  const [apiKeys, setApiKeys] = useState<IApiKey[]>([]);
  const [openProjectModal, setOpenProjectModal] = useState(false);
  const [loading, setLoading] = useState(true);

  const [project, setProject] = useState<DocumentData>();
  // const [user, loading] = useAuthState(auth);
  // Link params
  const linkParams = useParams<{ projectId: string }>();
  // Modals
  const [apiKeyModal, setApiKeyModal] = useState<{
    key: IApiKey;
    open: boolean;
  }>({ key: {} as IApiKey, open: false });
  const [projectSetup, setProjectSetup] = useState(false);
  // Site States
  // const [dataLoading, setDataLoading] = useState(true);
  const [error, setError] = useState("");

  const TableHeader: React.FC<{ styling?: string }> = ({
    children,
    styling,
  }) => {
    return (
      <th
        className={`text-xs font-medium text-gray-500 dark:text-gray-300 py-1 ${styling}`}
      >
        {children}
      </th>
    );
  };

  const handleFirebaseError = (error: FirebaseError) => {
    setError(error.code);
  };

  const fetchProjectInfo = useCallback(() => {
    const getProjectInfo = async () => {
      if (linkParams.projectId) {
        /**
         * Set Project Info
         */
        // const docRef = doc(db, "projects", linkParams.projectId || "")
        // getDoc(docRef).then(docSnap => {
        //     if (docSnap.exists()) {
        //         console.log("Document data:", docSnap.data());
        //         setProject(docSnap)
        //     } else {
        //         setError(true)
        //         // doc.data() will be undefined in this case
        //         console.log("No such document!");
        //     }
        // })

        try {
          onSnapshot(
            doc(db, "projects", linkParams.projectId || ""),
            (doc) => {
              if (doc.exists()) {
                setProject(doc);
              } else {
                setError("not-found");
                // doc.data() will be undefined in this case
                console.log("Project was not found");
              }
            },
            (err) => {
              handleFirebaseError(err);
            }
          );

          /**
           * Set Api keys
           */

          const q = query(
            collection(db, "api-keys"),
            where("pid", "==", linkParams.projectId)
          );
          onSnapshot(q, (querySnapshot) => {
            const keys: IApiKey[] = [];
            querySnapshot.forEach((doc) => {
              keys.push({
                id: doc.id || "",
                key: doc.data().key || "invalid-key-in-database",
                created: new Date(
                  new Timestamp(
                    doc.data().created.seconds,
                    doc.data().created.nanoseconds
                  ).toDate()
                ),
                accessAtomic: doc.data().access_atomic,
                accessChain: doc.data().access_chain,
                accessHistoryV1: doc.data().access_history_v1,
                accessHistoryV2: doc.data().access_history_v2,
                blocked: doc.data().blocked,
              });
            });
            const sortedKeys = keys.sort(
              (a, b) => a.created.getTime() - b.created.getTime()
            );
            setApiKeys(sortedKeys);
            setLoading(false);
          });
        } catch (e) {
          setLoading(false);
        }

        // const querySnapshot = await getDocs(q);
        // const keys: any[] = [];
        // querySnapshot.forEach((doc) => {
        //     // doc.data() is never undefined for query doc snapshots
        //     keys.push({
        //         key: doc.data().key || "invalid-key-in-database",
        //         created: moment(new Date(new Timestamp(doc.data().created.seconds, doc.data().created.nanoseconds).toDate())).format("hh:mm DD.MM.YYYY"),
        //         access_atomic: doc.data().access_atomic,
        //         access_chain: doc.data().access_chain,
        //         access_history_v1: doc.data().access_history_v1,
        //         access_history_v2: doc.data().access_history_v2,
        //         blocked: doc.data().blocked
        //     })
        // });
        // setApiKeys(keys)
      }
    };
    getProjectInfo();
  }, [linkParams.projectId]);

  useEffect(() => {
    fetchProjectInfo();
  }, [fetchProjectInfo]);

  useEffect(() => {
    if (project) {
      const data = project.data();
      setProjectSetup(data.chain === null || data.name === null);
    }
  }, [project]);

  // todo: check if user has permission for project

  const handleGenerateApiKey = async () => {
    const response = await fetchFromAPI("generate-api-key", "POST", {
      pid: linkParams.projectId,
      chain: project?.data().chain || null,
    });
    console.log(response);
  };

  return (
    <div>
      <ProjectSetup
        setOpen={setProjectSetup}
        open={projectSetup}
        pid={project?.id}
      />
      <ProjectError error={error} />

      <EditProject
        open={openProjectModal}
        setOpen={setOpenProjectModal}
        project={project}
      />

      <EditApiKey
        open={apiKeyModal.open}
        close={() => {
          setApiKeyModal({ ...apiKeyModal, open: false });
        }}
        apiKey={apiKeyModal.key}
      />

      <h1 className="text-3xl font-bold dark:text-gray-100">
        {project?.data().name || (
          <Loader width="15ch" height="40px" className="-mb-2" />
        )}
      </h1>
      <div className="mt-10">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900 dark:text-gray-100">
              Project API Keys
            </h1>
            <p className="mt-2 text-sm text-gray-700 dark:text-gray-300">
              A list of API Keys associated with this project
            </p>
          </div>

          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            {!loading && (
              <>
                <button
                  type="button"
                  className="inline-flex items-center justify-center rounded-md border border-transparent bg-black px-4 py-2 text-sm font-medium text-white shadow-sm hover:border-black hover:text-black hover:bg-white sm:w-auto dark:bg-gray-700 dark:bg-opacity-60 dark:border dark:text-gray-200 dark:hover:bg-dark-400 dark:border-gray-500 dark:border-opacity-70  dark:hover:text-gray-100 dark:hover:border-gray-700 transition duration-200 mr-2"
                  // onClick={() => {navigate("/plans/select")}}
                  onClick={() => {
                    setOpenProjectModal(!openProjectModal);
                  }}
                >
                  Edit Project
                </button>
                <button
                  type="button"
                  className="inline-flex items-center justify-center rounded-md border border-transparent bg-black px-4 py-2 text-sm font-medium text-white shadow-sm hover:border-black hover:text-black hover:bg-white sm:w-auto dark:bg-gray-700 dark:bg-opacity-60 dark:border dark:text-gray-200 dark:hover:bg-dark-400 dark:border-gray-500  dark:hover:text-gray-100 dark:hover:border-gray-700 transition duration-200"
                  // onClick={() => {navigate("/plans/select")}}
                  onClick={handleGenerateApiKey}
                >
                  Add API Key
                </button>
              </>
            )}
          </div>
        </div>
        <div className="mt-4 rounded-lg bg-yellow-50 dark:bg-[#2a291e] p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationIcon
                className="h-5 w-5 text-yellow-400 dark:text-gray-100"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-yellow-800 dark:text-gray-100">
                Never share your API Keys
              </h3>
              <div className="mt-2 text-sm text-yellow-700 dark:text-gray-200">
                <p>
                  Handle your API Keys with care and do not share your keys with
                  others. Our team will never ask you for API keys. Be careful
                  when integrating your Key into your applications and prevent
                  leakage of your keys in repositories and frontend. If a key
                  got leaked, we recommend generating a new one
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                {/* API Key Table */}

                {/* API Key Table - Has Keys */}
                {!loading && apiKeys.length > 0 && (
                  <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                    <thead>
                      <tr className="bg-gray-50 dark:bg-dark-800 dark:bg-opacity-70 tracking-wider uppercase ">
                        <TableHeader styling="pr-4 pl-6 text-left">
                          Api Key
                        </TableHeader>
                        <TableHeader styling="text-left pr-4">
                          Endpoint
                        </TableHeader>
                        <TableHeader styling="w-28 px-4">
                          <span className="hidden lg:block">Chain</span>
                          <ApiSVG className="h-4 w-4 lg:hidden stroke-gray-700 dark:stroke-gray-200" />
                        </TableHeader>
                        <TableHeader styling="w-28 px-4 whitespace-nowrap">
                          <span className="hidden lg:block">History V1</span>
                          <HistorySVG className="h-4 w-4 lg:hidden stroke-gray-700 dark:stroke-gray-200" />
                        </TableHeader>
                        <TableHeader styling="w-28 px-4 whitespace-nowrap">
                          <span className="hidden lg:block">History V2</span>
                          <HyperionSVG className="h-4 w-4 lg:hidden stroke-gray-700 dark:stroke-gray-200" />
                        </TableHeader>
                        <TableHeader styling="w-28 px-4">
                          <span className="hidden lg:block">Atomic</span>
                          <AtomicSVG className="h-4 w-4 lg:hidden stroke-gray-700 dark:stroke-gray-200" />
                        </TableHeader>
                        <TableHeader styling="text-left px-4">
                          Created On
                        </TableHeader>
                        <TableHeader styling="w-2" />
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200 dark:bg-dark-600 dark:bg-opacity-60 dark:divide-gray-700">
                      {apiKeys.map((apiKey) => (
                        <tr key={apiKey.key}>
                          <td className="whitespace-nowrap pr-4 pl-6 text-sm text-gray-500 dark:text-gray-300">
                            <ClickToCopy text={apiKey.key} styling="py-4">
                              <div className="flex items-center">
                                <svg
                                  className="h-2 w-2 text-green-400"
                                  fill="currentColor"
                                  viewBox="0 0 8 8"
                                >
                                  <circle cx={4} cy={4} r={3} />
                                </svg>
                                <p className="blur-sm ml-2">
                                  {apiKey.key.slice(
                                    0,
                                    Math.min(32, apiKey.key.length)
                                  )}
                                </p>
                              </div>
                            </ClickToCopy>
                          </td>

                          <td className="whitespace-nowrap text-sm text-gray-500 dark:text-gray-300 group relative cursor-pointer pr-4">
                            <ClickToCopy
                              text="https://pro.wax.oneapi.dev"
                              styling="py-4"
                            >
                              <p>https://pro.wax.oneapi.dev</p>
                            </ClickToCopy>
                          </td>
                          <td className="py-4">
                            {apiKey.accessChain ? (
                              <CheckCircleIcon
                                className="h-5 w-5 text-green-400 mx-auto"
                                aria-hidden="true"
                              />
                            ) : (
                              <MinusCircleIcon className="h-5 w-5 text-cyan-500 dark:text-dark-button mx-auto" />
                            )}
                          </td>
                          <td className="py-4">
                            {apiKey.accessHistoryV1 ? (
                              <CheckCircleIcon
                                className="h-5 w-5 text-green-400 mx-auto"
                                aria-hidden="true"
                              />
                            ) : (
                              <MinusCircleIcon className="h-5 w-5 text-cyan-500 dark:text-dark-button mx-auto" />
                            )}
                          </td>
                          <td className="py-4">
                            {apiKey.accessHistoryV2 ? (
                              <CheckCircleIcon
                                className="h-5 w-5 text-green-400 mx-auto"
                                aria-hidden="true"
                              />
                            ) : (
                              <MinusCircleIcon className="h-5 w-5 text-cyan-500 dark:text-dark-button mx-auto" />
                            )}
                          </td>
                          <td className="py-4">
                            {apiKey.accessAtomic ? (
                              <CheckCircleIcon
                                className="h-5 w-5 text-green-400 mx-auto"
                                aria-hidden="true"
                              />
                            ) : (
                              <MinusCircleIcon className="h-5 w-5 text-cyan-500 dark:text-dark-button mx-auto" />
                            )}
                          </td>
                          <td className="whitespace-nowrap py-4 text-sm text-gray-500 text-left px-4 dark:text-gray-300">
                            {moment(apiKey.created).format("HH:mm DD.MM.YYYY")}
                          </td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            <button
                              type="button"
                              className="w-full inline-flex justify-center rounded-md border border-transparent text-indigo-300 hover:text-indigo-500 px-4 py-2 text-base font-medium  sm:ml-3 sm:w-auto sm:text-sm dark:text-highlight-dark cursor-pointer"
                              onClick={() =>
                                setApiKeyModal({
                                  key: apiKey,
                                  open: true,
                                })
                              }
                            >
                              Edit
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}

                {/* API Key Table - No Keys */}
                {!loading && apiKeys.length === 0 && (
                  <div className="relative block w-full p-16 text-center focus:outline-none text-gray-900 dark:bg-dark-600 dark:bg-opacity-60 dark:text-gray-100">
                    <KeyIcon
                      className=" mx-auto h-12 text-gray-400 dark:text-gray-300"
                      aria-hidden="true"
                    />
                    <span className="mt-2 block text-sm font-medium ">
                      This project does not have any api Keys yet. Start by
                      adding the first API key
                    </span>
                  </div>
                )}

                {/* API Key Table - Loading */}

                {loading && (
                  <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                    <thead>
                      <tr className="bg-gray-50 dark:bg-dark-800 dark:bg-opacity-70 tracking-wider uppercase ">
                        <TableHeader styling="pr-4 pl-6 text-left">
                          Api Key
                        </TableHeader>
                        <TableHeader styling="text-left pr-4">
                          Endpoint
                        </TableHeader>
                        <TableHeader styling="w-28 px-4">
                          <span className="hidden lg:block">Chain</span>
                          <ApiSVG className="h-4 w-4 lg:hidden stroke-gray-700 dark:stroke-gray-200" />
                        </TableHeader>
                        <TableHeader styling="w-28 px-4 whitespace-nowrap">
                          <span className="hidden lg:block">History V1</span>
                          <HistorySVG className="h-4 w-4 lg:hidden stroke-gray-700 dark:stroke-gray-200" />
                        </TableHeader>
                        <TableHeader styling="w-28 px-4 whitespace-nowrap">
                          <span className="hidden lg:block">History V2</span>
                          <HyperionSVG className="h-4 w-4 lg:hidden stroke-gray-700 dark:stroke-gray-200" />
                        </TableHeader>
                        <TableHeader styling="w-28 px-4">
                          <span className="hidden lg:block">Atomic</span>
                          <AtomicSVG className="h-4 w-4 lg:hidden stroke-gray-700 dark:stroke-gray-200" />
                        </TableHeader>
                        <TableHeader styling="text-left px-4">
                          Created On
                        </TableHeader>
                        <TableHeader styling="w-2" />
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200 dark:bg-dark-600 dark:bg-opacity-60 dark:divide-gray-700">
                      {Array.from({ length: 5 }).map((_, idx) => (
                        <tr key={idx} className="h-[4.5rem]">
                          <td className="pl-6 pr-2">
                            <span className="flex items-center">
                              <Loader height="25px" width="250px" />
                            </span>
                          </td>
                          <td>
                            <span className="flex items-center">
                              <Loader height="25px" width="180px" />
                            </span>
                          </td>
                          <td>
                            <span className="flex justify-center items-center">
                              <Loader
                                height="25px"
                                width="25px"
                                circle={true}
                              />
                            </span>
                          </td>
                          <td className="">
                            <span className="flex justify-center items-center">
                              <Loader
                                height="25px"
                                width="25px"
                                circle={true}
                              />
                            </span>
                          </td>
                          <td>
                            <span className="flex justify-center items-center">
                              <Loader
                                height="25px"
                                width="25px"
                                circle={true}
                              />
                            </span>
                          </td>
                          <td>
                            <span className="flex justify-center items-center">
                              <Loader
                                height="25px"
                                width="25px"
                                circle={true}
                              />
                            </span>
                          </td>
                          <td className="px-4 ">
                            <span className="flex items-center">
                              <Loader height="25px" width="100px" />
                            </span>
                          </td>
                          <td className="pl-3 pr-4 sm:pr-6">
                            <button
                              type="button"
                              className="w-full inline-flex justify-center rounded-md border border-transparent text-indigo-300 hover:text-indigo-500 px-4 py-2 text-base font-medium  sm:ml-3 sm:w-auto sm:text-sm dark:text-highlight-dark cursor-pointer"
                              // onClick={() => setOpen(true)}
                              disabled={true}
                            >
                              <Loader height="25px" width="4ch" />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Project Members */}
      <div className="mt-10">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900 dark:text-gray-100">
              Project Members
            </h1>
            <p className="mt-2 text-sm text-gray-700 dark:text-gray-300">
              All members on this project
            </p>
          </div>

          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <button
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-black dark:bg-gray-700 dark:bg-opacity-60 dark:border dark:text-gray-200 dark:border-gray-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:border-black hover:text-black hover:bg-white sm:w-auto dark:hover:bg-dark-400 dark:hover:text-gray-100 dark:hover:border-gray-700 transition duration-100"
              // onClick={() => {navigate("/plans/select")}}
            >
              Invite Colleagues
            </button>
          </div>
        </div>
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                {false && (
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 dark:bg-dark-800"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 dark:bg-dark-800"
                        >
                          Role
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {apiKeys.map((apiKey) => (
                        <tr key={apiKey.key}>
                          <td className="whitespace-nowrap py-4 text-sm font-medium text-gray-900 sm:pl-6 inline-flex items-center">
                            <svg
                              className="-ml-0.5 mr-3 h-2 w-2 text-green-400"
                              fill="currentColor"
                              viewBox="0 0 8 8"
                            >
                              <circle cx={4} cy={4} r={3} />
                            </svg>
                            <p className="blur-sm">
                              {apiKey.key.slice(
                                0,
                                Math.min(32, apiKey.key.length)
                              )}
                            </p>
                            <CopyToClipboard text={apiKey.key}>
                              <button className="bg-gray-100 px-2 text-gray-800 dark:text-gray-200 p-1 ml-2 rounded-sm">
                                copy
                              </button>
                            </CopyToClipboard>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                            endpoint
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            WAX Mainnet
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                            <CheckCircleIcon
                              className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                              aria-hidden="true"
                            />
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                            <CheckCircleIcon
                              className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                              aria-hidden="true"
                            />
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                            <CheckCircleIcon
                              className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                              aria-hidden="true"
                            />
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                            <CheckCircleIcon
                              className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                              aria-hidden="true"
                            />
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-300">
                            {apiKey.created}
                          </td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            <button
                              type="button"
                              disabled={true}
                              className="w-full inline-flex justify-center rounded-md border border-transparent hover:text-indigo-500 px-4 py-2 text-base font-medium text-white sm:ml-3 sm:w-auto sm:text-sm"
                              // onClick={() => setOpen(true)}
                            >
                              Regenerate
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
                {true && (
                  <div className="relative block w-full p-24 text-center focus:outline-none dark:bg-dark-600 dark:bg-opacity-60">
                    <UserGroupIcon
                      className=" mx-auto h-12 text-gray-400 dark:text-gray-300"
                      aria-hidden="true"
                    />
                    <span className="mt-2 block text-sm font-medium text-gray-900 dark:text-gray-100">
                      There are no other members on this project. Get yourself
                      some friends
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SingleProject;
