import React, { useCallback, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../../services/firebase";
import {
  collection,
  getDocs,
  query,
  where,
  Timestamp,
} from "firebase/firestore";
import moment from "moment";

interface IProject {
  name: string;
  chain: string;
  created: string;
  pid: string;
  owner: string;
  plan: string;
}

interface IApiKey {
  chain: string;
  created: string;
  name: string;
  owner: string;
  pid: string;
  plan: string;
}

export const useGetProjects = () => {
  const [user] = useAuthState(auth);
  const [projects, setProjects] = useState<IProject[]>([]);
  const [loading, setLoading] = useState(true);

  const getProjects = useCallback(async () => {
    try {
      if (user && user.uid) {
        const q = query(
          collection(db, "projects"),
          where("owner", "==", user?.uid)
        );
        const docs = await getDocs(q);
        const keys: IApiKey[] = [];
        docs.forEach((doc) => {
          keys.push({
            name: doc.data().name || "Unnamed Project",
            chain: doc.data().chain || "Setup your project",
            created: moment(
              new Date(
                new Timestamp(
                  doc.data().created.seconds,
                  doc.data().created.nanoseconds
                ).toDate()
              )
            ).format("MMMM DD, YYYY - hh:mm"),
            owner: doc.data().owner,
            pid: doc.id,
            plan: doc.data().plan,
          });
        });
        setProjects(keys);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    getProjects();
  }, [getProjects]);

  return { projects, loading };
};
